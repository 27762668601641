import React from 'react'
import { Box, Typography, Grid, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  Camera,
  Search,
  CloudQueue,
  Delete,
  SyncAlt,
  AddCircle,
} from '@mui/icons-material'

const GridWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.accentdark,
}))

const FeatureCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
}))

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: '50%',
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
    color: theme.palette.background.accent,
  },
}))

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

const FeatureTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"DM Sans", sans-serif',
  fontWeight: 700,
  fontSize: '1.2rem',
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
}))

const FeatureDescription = styled(Typography)(({ theme }) => ({
  fontFamily: '"Space Mono", monospace',
  fontSize: '1rem',
  color: theme.palette.text.secondary,
}))

const FeaturesGrid = () => {
  const features = [
    {
      icon: <Camera />,
      title: 'Capture item photos',
      description:
        "No need to create complex location hierarchies or assign barcode labels—simply take a photo of your item, add a description, and you're done.",
    },
    {
      icon: <Search />,
      title: 'Powerful search functionality',
      description: 'Find any item instantly with our robust full-text search.',
    },
    {
      icon: <CloudQueue />,
      title: 'Secure cloud storage & sync',
      description:
        'Store your data in the cloud for seamless access across devices. Keep your information private and secure.',
    },
    {
      icon: <Delete />,
      title: 'Free up device memory',
      description:
        'With all your data stored in the cloud, you can save valuable space on your devices.',
    },
  ]

  return (
    <Grid container spacing={2}>
      {features.map((feature, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <FeatureCard>
            <IconWrapper>{feature.icon}</IconWrapper>
            <ContentWrapper>
              <FeatureTitle>{feature.title}</FeatureTitle>
              <FeatureDescription>{feature.description}</FeatureDescription>
            </ContentWrapper>
          </FeatureCard>
        </Grid>
      ))}
    </Grid>
  )
}

export default FeaturesGrid
