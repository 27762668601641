import {getApps, initializeApp} from 'firebase/app';
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions,
} from 'firebase/functions';
import {firebaseConfig, verifyConfig} from './firebase-config';
import { verify } from 'crypto';

class FirebaseService {
  initialized = false;
  _functions?: Functions;

  public get functions(): Functions {
    if (!this.initialized || !this._functions) throw new Error('Firebase service not initialized');
    return this._functions;
  }

  constructor() {
    const useFunctionsEmulator = process.env.REACT_APP_USE_FUNCTIONS_EMULATOR;

    const apps = getApps();
    let app;
    if (apps.length) {
      app = apps[0];
    } else {
      try{
        if(!verifyConfig(firebaseConfig)){
          throw new Error('Invalid firebase config');
        }
        app = initializeApp(firebaseConfig);
      }catch(error){
        console.log('failed to initialize firebase app', error);
      }
    }

    if(!app){
      return;
    }

    this._functions = getFunctions(app);

    if(useFunctionsEmulator === 'true'){
      try {
        console.log('using functions emulator');
        connectFunctionsEmulator(this._functions, '127.0.0.1', 5001);
      } catch (error) {
        console.log('failed functions emulator', error);
      }
    }
    this.initialized = true;
  }
}

const firebaseService = new FirebaseService();
export default firebaseService;
