import React, { useEffect, useRef } from 'react'
import videojs from 'video.js'
import Player from 'video.js/dist/types/player'
import 'video.js/dist/video-js.css'

interface VideoJsPlayerProps {
  src: string
  poster?: string
}

export const VideoJsPlayer: React.FC<VideoJsPlayerProps> = ({
  src,
  poster,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const playerRef = useRef<Player | null>(null)

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      playerRef.current = videojs(videoElement, {
        controls: true,
        fluid: true,
        responsive: true,
        preload: 'auto',
      })
    }

    // Update the video source when the src prop changes
    const player = playerRef.current
    player.src({ type: 'video/mp4', src })
    if (poster) {
      player.poster(poster)
    }

    // Cleanup function
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose()
        playerRef.current = null
      }
    }
  }, [src, poster])

  return (
    <div data-vjs-player>
      <video ref={videoRef} className="video-js" />
    </div>
  )
}
