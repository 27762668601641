import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const PageSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))
