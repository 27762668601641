import React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import { Link as RouterLink } from 'react-router-dom'
import { Typography } from '@mui/material'

const Banner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
}))

const BannerTextLink = styled(RouterLink)(({ theme }) => ({
  fontSize: '1.1em',
  fontFamily: `"DM Sans", sans-serif`,
  padding: theme.spacing(2),
  color: theme.palette.primary.light,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.primary.light, // Adjust this color as needed
  },
}))

const HowItWorksBanner: React.FC = () => {
  return (
    <Banner>
      <BannerTextLink to="/howitworks">
        Curious to see it in action? Visit our <strong>How It Works</strong>{' '}
        page to explore video previews of Last Seen's key features
      </BannerTextLink>
      <Box sx={[{ display: 'flex', justifyContent: 'center', gap: '20px' }]}>
        {['video-thumb2.png', 'video-thumb1.png', 'video-thumb3.png'].map(
          (image, index) => (
            <RouterLink to="/howitworks" key={index}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/images/${image}`}
                alt={`Feature preview ${index + 1}`}
              />
            </RouterLink>
          )
        )}
      </Box>
    </Banner>
  )
}

export default HowItWorksBanner
