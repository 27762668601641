import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export const PageTitleWrapper = styled(Box)({
  position: 'relative',
  display: 'inline-block',
  marginBottom: 20,
})

export const PageTitleText = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  fontSize: '2em',
  letterSpacing: '0.14em',
  textAlign: 'left',
  fontFamily: `"DM Sans", sans-serif`,
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontSize: '1.5em',
    letterSpacing: '0.1em',
  },
}))

export const PageTitleSubText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontStyle: 'italic',
  fontSize: '1.3em',
  textAlign: 'right',
  fontFamily: `"Space Mono", monospace`,
  position: 'absolute',
  right: 0,
  bottom: -20, // Adjust this value to control the vertical position of the subtitle
  whiteSpace: 'nowrap',
  color: theme.palette.primary.contrastText,
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    right: 'auto',
    bottom: 'auto',
    textAlign: 'center',
  },
}))
