import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: 700,
    textDecoration: 'none',
    letterSpacing: '0.1em',
    textAlign: 'center',
    fontSize: '2em',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.3em',
      },
  }));

  const Logo = ({isDev}: {isDev: boolean}) => {
  
    return <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
        <Title>LAST</Title>
        <img src={process.env.PUBLIC_URL + '/images/logo' + (isDev ? '.dev' : '') + '.png'} alt="Logo" width={75} height={75}/>
        <Title  sx={{ml: 0.5}}>SEEN</Title>
        </Box>
  }

  export default Logo;
