import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  Button,
  Box,
  TextField,
  Alert,
  Paper,
  Typography,
  Container,
  Link,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { httpsCallable } from 'firebase/functions'
import firebaseService from '../firebase-service'
import {
  TopSection,
  PageTitleWrapper,
  PageTitleText,
} from '../styled/PageTitle'

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}))

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}))

const sendRequest = httpsCallable<{ email: string; recaptcha: string }, void>(
  firebaseService.functions,
  'accountDelete'
)

const AccountDeletionRequest: React.FC = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<boolean>(false)
  const [captchaValue, setCaptchaValue] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setError(null)

    if (!captchaValue) {
      setError('Please complete the reCAPTCHA.')
      return
    }

    try {
      setLoading(true)
      await sendRequest({ email, recaptcha: captchaValue })
      setSuccess(true)
      setEmail('')
    } catch (error) {
      setError((error as any).message || 'An error occurred')
      console.error('Error submitting request:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container maxWidth="md">
      <TopSection>
        <PageTitleWrapper>
          <PageTitleText>DELETE ACCOUNT</PageTitleText>
        </PageTitleWrapper>
      </TopSection>
      <StyledPaper elevation={3}>
        <StyledForm onSubmit={handleSubmit}>
          <Alert severity="info">
            <Typography variant="subtitle1" fontWeight="bold">
              iOS users:
            </Typography>
            <Typography>
              If you used "Sign in with Apple" and chose not to share your
              email, please enter the anonymized email address. You can find it
              in the Settings tab of the LastSeen application or in your{' '}
              <Link
                href="https://support.apple.com/en-ca/102571"
                target="_blank"
                rel="noreferrer"
              >
                Apple ID settings
              </Link>
              .
            </Typography>
          </Alert>

          <TextField
            fullWidth
            id="email-address"
            label="Account Email Address"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <Box display="flex" justifyContent="center">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
              onChange={(value: any) => setCaptchaValue(value)}
            />
          </Box>

          <Typography variant="body1">By submitting this form:</Typography>
          <ol>
            <Typography component="li">
              You are requesting the deletion of your account and all associated
              data.
            </Typography>
            <Typography component="li">
              We will send a verification email to the address you provide here.
            </Typography>
            <Typography component="li">
              You must have access to this email to complete the account
              deletion process.
            </Typography>
          </ol>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            fullWidth
          >
            {loading ? 'Sending...' : 'Submit Request'}
          </Button>

          {error && <Alert severity="error">{error}</Alert>}
          {success && (
            <Alert severity="success">
              Your delete account request has been submitted. The processing of
              your request may take up to a few days. You will receive an email
              with further instructions.
            </Alert>
          )}
        </StyledForm>
      </StyledPaper>
    </Container>
  )
}

export default AccountDeletionRequest
