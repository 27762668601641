export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export const verifyConfig = ( firebaseConfig: any ) => {
  return Object.keys(firebaseConfig).every((key) => {
    if (firebaseConfig[key] === undefined) {
      console.error(`Firebase config key ${key} is undefined`);
      return false;
    }
    return true;
  });
};
