import { light } from '@mui/material/styles/createPalette'

const themeConfig = {
  palette: {
    primary: {
      main: '#324040',
      contrastText: '#e67e22',
      light: '#5C4523',
    },
    standout: {
      main: '#000',
      accent: '#FFCC00',
    },

    background: {
      default: '#f5f5f5',
      accent: 'cornsilk',
      accentdark: 'navajowhite',
    },
    secondary: {
      main: '#0e5a50',
      contrastText: '#e67e22',
    },
  },
  typography: {
    allVariants: {
      color: '#1e2022',
    },
  },
}

export default themeConfig
