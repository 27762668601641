import React, { useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  useMediaQuery,
  Paper,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { PageContent } from '../styled/PageContent'
import { PageSection } from '../styled/PageSection'
import { VideoJsPlayer } from '../components/VideoJsPlayer'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  PageTitleText,
  PageTitleWrapper,
  TopSection,
} from '../styled/PageTitle'
import { LargeText } from '../styled/LargeText'
import AppDownloadCTA from '../components/AppDownloadCTA'

interface SectionData {
  title: string
  summary: string
  description: string
  videoSrc: string
  posterSrc: string
}

interface TabCardProps {
  isActive: boolean
}

interface ScreenSizeIndicatorProps {
  isSmallScreen: boolean
}

const ContentContainer = styled(Box)<ScreenSizeIndicatorProps>(
  ({ theme, isSmallScreen }) => ({
    display: 'flex',
    flexDirection: isSmallScreen ? 'column' : 'row',
    gap: theme.spacing(2),
  })
)

const TabCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  flex: 1,
}))

const TabCard = styled(Card)<TabCardProps & ScreenSizeIndicatorProps>(
  ({ theme, isActive, isSmallScreen }) => ({
    cursor: isSmallScreen ? 'default' : 'pointer',
    backgroundColor: isActive
      ? theme.palette.background.accentdark
      : theme.palette.background.paper,
    color: isActive
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary,
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.short,
    }),
  })
)

const CardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: `"DM Sans", sans-serif`,
  fontSize: '1.5em',
  fontWeight: 700,
}))

const CardSummary = styled(Typography)(({ theme }) => ({
  fontFamily: `"Space Mono", monospace`,
  fontSize: '1.1em',
  fontWeight: 600,
  fontStyle: 'italic',
  color: theme.palette.primary.contrastText,
  marginBottom: theme.spacing(2),
}))

const CardDescription = styled(Typography)(({ theme }) => ({
  fontFamily: `"Space Mono", monospace`,
  fontSize: '1em',
  color: theme.palette.primary.light,
}))

const VideoContainer = styled(Paper)<ScreenSizeIndicatorProps>(
  ({ theme, isSmallScreen }) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: isSmallScreen ? 0 : theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: '100%', // Ensure the container takes full width
  })
)

const VideoContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between', // Distribute space evenly
  gap: theme.spacing(2),
  width: '100%',
}))

const PhoneFrame = styled(Box)(({ theme }) => ({
  flex: 1,
  position: 'relative',
  maxWidth: '375px',
  width: '100%',
  aspectRatio: '9 / 19.5',
  borderRadius: '40px',
  padding: '10px',
  backgroundColor: 'black',
  boxShadow: '0 0 20px rgba(0,0,0,0.1)',
  margin: '0 auto',
  '@media print': {
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
  },
}))

const PhoneContent = styled(Box)({
  position: 'absolute',
  top: '10px',
  left: '10px',
  right: '10px',
  bottom: '10px',
  borderRadius: '30px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const PrintImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  display: 'none',
  '@media print': {
    display: 'block',
  },
})

const VideoPlayerWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  '@media print': {
    display: 'none',
  },
})

const NavigationArrow = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(150, 50, 50, 0.5)',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: 'rgba(150, 50, 50, 0.8)',
  },
  flexShrink: 0, // Prevent the buttons from shrinking
}))

const HowItWorks: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [key, setKey] = useState(0)

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const sections: SectionData[] = [
    {
      title: 'Add Item and Assign Place',
      summary: 'Easily catalog your belongings',
      description: `Adding an item is a breeze: tap "Add Item," snap a photo, and describe it by typing or dictating. Then, link the item to a place by searching your locations or creating a new one with its own photo and description.`,
      videoSrc: 'clips/add-item.mp4',
      posterSrc: 'clips/add-item-poster.png',
    },
    {
      title: 'Find Places and Items',
      summary: 'Powerful search at your fingertips',
      description: `Discover the power of dynamic search - as you type, the app instantly filters your items and places, showing you the most relevant results. It even adapts to typos and understands your intent, so you'll find what you're looking for no matter how you spell it.`,
      videoSrc: 'clips/search.mp4',
      posterSrc: 'clips/search-poster.png',
    },
    {
      title: 'Browse and Adjust',
      summary: 'Manage your inventory with ease',
      description: `The Settings tab gives you a comprehensive view of your entire inventory. Browse all items and places in easy-to-navigate lists. Update rarely-used items or remove outdated locations with a simple tap. View details, make changes, and use safety features like deletion confirmations.`,
      videoSrc: 'clips/settings.mp4',
      posterSrc: 'clips/settings-poster.png',
    },
  ]

  const handleTabChange = (index: number) => {
    if (!isSmallScreen) {
      setActiveTab(index)
      setKey((prevKey) => prevKey + 1)
    }
  }

  const handlePrevious = () => {
    setActiveTab((prev) => (prev > 0 ? prev - 1 : sections.length - 1))
  }

  const handleNext = () => {
    setActiveTab((prev) => (prev < sections.length - 1 ? prev + 1 : 0))
  }

  const renderVideoCard = (section: SectionData, index: number) => (
    <VideoContainer key={`video-${index}`} isSmallScreen={isSmallScreen}>
      <VideoContent>
        {!isSmallScreen && index === activeTab && (
          <NavigationArrow onClick={handlePrevious}>
            <ArrowBackIosNewIcon />
          </NavigationArrow>
        )}
        <PhoneFrame>
          <PhoneContent>
            <PrintImage src={section.posterSrc} alt={section.title} />
            <VideoPlayerWrapper>
              <VideoJsPlayer
                key={`player-${index}-${key}`}
                src={section.videoSrc}
                poster={section.posterSrc}
              />
            </VideoPlayerWrapper>
          </PhoneContent>
        </PhoneFrame>
        {!isSmallScreen && index === activeTab && (
          <NavigationArrow onClick={handleNext}>
            <ArrowForwardIosIcon />
          </NavigationArrow>
        )}
      </VideoContent>
    </VideoContainer>
  )

  return (
    <PageContent>
      <TopSection>
        <PageTitleWrapper>
          <PageTitleText>HOW IT WORKS</PageTitleText>
        </PageTitleWrapper>
      </TopSection>
      <PageSection>
        <CardDescription>
          Last Seen makes it effortless to record and locate your belongings.
          Simply snap photos, add descriptions, and assign locations. Our
          powerful search feature helps you find items instantly, while secure
          cloud storage keeps your data accessible across devices - no backups
          or phone memory usage required. Just install the app and stay
          organized from anywhere.
        </CardDescription>
      </PageSection>
      <PageSection>
        <ContentContainer isSmallScreen={isSmallScreen}>
          {isSmallScreen ? (
            // Small screen layout: all cards and videos in a column
            sections.map((section, index) => (
              <React.Fragment key={index}>
                <TabCard isActive={true} isSmallScreen={true}>
                  <CardContent>
                    <CardTitle>{section.title}</CardTitle>
                    <CardSummary>{section.summary}</CardSummary>
                    <CardDescription>{section.description}</CardDescription>
                  </CardContent>
                </TabCard>
                {renderVideoCard(section, index)}
              </React.Fragment>
            ))
          ) : (
            // Large screen layout: tabs on the left, active video on the right
            <>
              <TabCardContainer>
                {sections.map((section, index) => (
                  <TabCard
                    key={index}
                    isActive={activeTab === index}
                    isSmallScreen={false}
                    onClick={() => handleTabChange(index)}
                  >
                    <CardContent>
                      <CardTitle>{section.title}</CardTitle>
                      <CardSummary>{section.summary}</CardSummary>
                      <CardDescription>{section.description}</CardDescription>
                    </CardContent>
                  </TabCard>
                ))}
              </TabCardContainer>
              {renderVideoCard(sections[activeTab], activeTab)}
            </>
          )}
        </ContentContainer>
      </PageSection>
      <AppDownloadCTA />
    </PageContent>
  )
}

export default HowItWorks
