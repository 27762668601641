import { Link } from '@mui/material'
import { PageContent } from '../styled/PageContent'
import { PageSection } from '../styled/PageSection'
import { PageSectionTitle } from '../styled/PageSectionTitle'
import {
  PageTitleText,
  PageTitleWrapper,
  TopSection,
} from '../styled/PageTitle'
import { Paragraph, ParagraphCondenced } from '../styled/Paragraph'

const Eula = () => {
  return (
    <PageContent>
      <PageSection>
        <PageTitleText>
          End-User License Agreement (EULA) for Last Seen (Android Version
        </PageTitleText>
        <Paragraph>Last Updated: October 4, 2024</Paragraph>
        <Paragraph>
          <strong>Note:</strong> This EULA applies only to the{' '}
          <strong>Android</strong> version of the <strong>Last Seen</strong> app
          available on the <strong>Google Play Store</strong>. If you are using
          the <strong>iOS</strong> version of the app, your use is governed by
          the{' '}
          <Link href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">
            <strong>Apple Standard EULA</strong>
          </Link>
          .
        </Paragraph>
      </PageSection>
      <PageSection>
        <PageSectionTitle>End-User License Agreement (EULA)</PageSectionTitle>
        <Paragraph>
          This End-User License Agreement ("Agreement") is a legal contract
          between you ("User" or "you") and 15156215 Canada Inc., doing business
          as Apperytime ("Company," "we," or "us"), concerning your use of the
          Last Seen mobile application ("App") available on the Google Play
          Store.
        </Paragraph>
        <Paragraph>
          By downloading, installing, or using the App, you agree to be bound by
          the terms of this Agreement. If you do not agree to these terms, do
          not use the App.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>1. License Grant</PageSectionTitle>
        <Paragraph>
          We grant you a non-exclusive, non-transferable, revocable license to
          use the App for your personal, non-commercial purposes on any
          compatible Android device that you own or control, in accordance with
          this Agreement.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>2. Restrictions on Use</PageSectionTitle>
        <Paragraph>
          You agree <strong>not</strong> to:
          <ul>
            <li>Use the App for any commercial or business purposes.</li>
            <li>Copy, modify, or create derivative works of the App.</li>
            <li>
              Reverse engineer, decompile, or attempt to extract the source code
              of the App.
            </li>
            <li>
              Sell, rent, lease, sublicense, distribute, or transfer the App.
            </li>
            <li>
              Use the App in any unlawful manner or in violation of any
              applicable laws.
            </li>
          </ul>
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>3. Intellectual Property Rights</PageSectionTitle>
        <Paragraph>
          All rights, title, and interest in and to the App, including all
          intellectual property rights, are owned by the Company. This Agreement
          does not grant you any rights to trademarks or service marks of the
          Company.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>4. Privacy and Data Collection</PageSectionTitle>
        <Paragraph>
          We may collect certain technical data and related information about
          your device and software to facilitate updates and improvements.
          Please refer to our <Link href="/privacy">Privacy Policy</Link> for
          details on how we collect and use information.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>5. Disclaimer of Warranties</PageSectionTitle>
        <Paragraph>
          The App is provided "as is" and "as available" without warranties of
          any kind. We disclaim all warranties, express or implied, including
          but not limited to warranties of merchantability, fitness for a
          particular purpose, and non-infringement.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>6. Limitation of Liability</PageSectionTitle>
        <Paragraph>
          To the maximum extent permitted by law, the Company shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages arising out of your use or inability to use the App.
          Our total liability shall not exceed the amount you paid for the App,
          if any.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>7. Indemnification</PageSectionTitle>
        <Paragraph>
          You agree to indemnify and hold harmless the Company, its affiliates,
          and their officers, directors, employees, and agents from any claims
          or liabilities arising out of your misuse of the App or violation of
          this Agreement.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>8. Termination</PageSectionTitle>
        <Paragraph>
          This Agreement is effective until terminated. Your rights under this
          Agreement will terminate automatically if you fail to comply with any
          term. Upon termination, you must cease all use of the App and delete
          all copies.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>9. Governing Law</PageSectionTitle>
        <Paragraph>
          This Agreement shall be governed by and construed in accordance with
          the laws of the Province of Ontario, Canada, and the federal laws of
          Canada applicable therein, without regard to conflict of law
          principles.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>10. Severability</PageSectionTitle>
        <Paragraph>
          If any provision of this Agreement is held invalid, the remaining
          provisions shall continue in full force and effect.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>11. Entire Agreement</PageSectionTitle>
        <Paragraph>
          This Agreement constitutes the entire agreement between you and the
          Company regarding the App and supersedes all prior agreements.
        </Paragraph>
      </PageSection>

      <PageSection>
        <PageSectionTitle>12. Contact Information</PageSectionTitle>
        <Paragraph>
          For questions about this Agreement, please contact us at:
        </Paragraph>
        <ParagraphCondenced>
          15156215 Canada Inc.
          <br />
          Doing business as Apperytime
          <br />
          Email:{' '}
          <Link href="mailto:support@lastseen.app">support@lastseen.app</Link>
        </ParagraphCondenced>
      </PageSection>
    </PageContent>
  )
}

export default Eula
