import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import GetAppIcon from '@mui/icons-material/GetApp'
import { Link as RouterLink } from 'react-router-dom'

const CTAWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: 'theme.palette.background.accentdark',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: theme.spacing(4),
}))

const CTATitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"DM Sans", sans-serif',
  fontWeight: 700,
  fontSize: '1.5rem',
  marginBottom: theme.spacing(2),
  color: theme.palette.text.primary,
}))

const CTALink = styled(RouterLink)(({ theme }) => ({
  fontFamily: '"DM Sans", sans-serif',
  fontSize: '1.1rem',
  color: theme.palette.primary.light,
  textDecoration: 'none',
  marginBottom: theme.spacing(3),
  '&:hover': {
    textDecoration: 'underline',
  },
}))

const AppStoreButton = styled('a')(({ theme }) => ({
  display: 'inline-block',
  textDecoration: 'none',
}))

const AppDownloadButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '4em',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '2em',
  },
}))

const AppDownloadCTA: React.FC = () => {
  return (
    <CTAWrapper>
      <CTATitle>Ready to Navigate Your Clutter?</CTATitle>
      <CTALink to="https://apps.apple.com/us/app/lastseen-app/id6477953801">
        Download Last Seen now and start organizing your belongings
        effortlessly.
      </CTALink>
      <AppDownloadButtons>
        <AppStoreButton
          href="https://apps.apple.com/us/app/lastseen-app/id6477953801"
          target="_blank"
          rel="noreferrer"
        >
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/app_store_badge.png`}
            alt="Download on the App Store"
            sx={{ width: 'auto', maxWidth: 300 }}
          />
        </AppStoreButton>
        <AppStoreButton
          href="https://play.google.com/store/apps/details?id=com.lastseen"
          target="_blank"
          rel="noreferrer"
        >
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/images/google_play_color.png`}
            alt="Get it on Google Play"
            sx={{ width: 'auto', maxWidth: 300 }}
          />
        </AppStoreButton>
      </AppDownloadButtons>
    </CTAWrapper>
  )
}

export default AppDownloadCTA
