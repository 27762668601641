import React from 'react'
import { useTheme, Theme } from '@mui/material/styles'
import { Box, Typography, Paper } from '@mui/material'
import { PageContent } from '../styled/PageContent'
import { PageTitleWrapper } from '../styled/PageTitle'
import { PageSection } from '../styled/PageSection'
import { PageSectionTitle } from '../styled/PageSectionTitle'

type ColorValue = string | { main: string; light?: string; dark?: string }

const ColorBox = ({ color, name }: { color: string; name: string }) => (
  <Paper elevation={3} sx={{ p: 2, m: 1, width: 200 }}>
    <Box sx={{ height: 100, width: '100%', backgroundColor: color, mb: 1 }} />
    <Typography variant="subtitle1">{name}</Typography>
    <Typography variant="body2">{color}</Typography>
  </Paper>
)

const renderColorBoxes = (
  colors: Record<string, ColorValue>,
  prefix: string = ''
) => {
  return Object.entries(colors).map(([key, value]) => {
    if (typeof value === 'string') {
      return (
        <ColorBox
          key={`${prefix}${key}`}
          color={value}
          name={`${prefix}${key}`}
        />
      )
    } else if (typeof value === 'object') {
      return (
        <React.Fragment key={`${prefix}${key}`}>
          <ColorBox color={value.main} name={`${prefix}${key} (Main)`} />
          {value.light && (
            <ColorBox color={value.light} name={`${prefix}${key} (Light)`} />
          )}
          {value.dark && (
            <ColorBox color={value.dark} name={`${prefix}${key} (Dark)`} />
          )}
        </React.Fragment>
      )
    }
    return null
  })
}

const ThemePreview: React.FC = () => {
  const theme = useTheme()

  const renderThemeSection = (sectionName: string, sectionData: any) => {
    if (typeof sectionData !== 'object' || sectionData === null) return null

    return (
      <PageSection key={sectionName}>
        <PageSectionTitle>{sectionName}</PageSectionTitle>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {renderColorBoxes(sectionData, `${sectionName}.`)}
        </Box>
      </PageSection>
    )
  }

  const renderTheme = (theme: Theme) => {
    return Object.entries(theme.palette).map(([key, value]) =>
      renderThemeSection(key, value)
    )
  }

  return (
    <PageContent>
      <PageTitleWrapper>THEME PREVIEW</PageTitleWrapper>
      {renderTheme(theme)}
    </PageContent>
  )
}

export default ThemePreview
