import { Link } from '@mui/material'
import { PageContent } from '../styled/PageContent'
import { PageSection } from '../styled/PageSection'
import { Paragraph } from '../styled/Paragraph'
import {
  PageTitleText,
  PageTitleWrapper,
  TopSection,
} from '../styled/PageTitle'
import { PageSectionTitle } from '../styled/PageSectionTitle'

const PrivacyPolicy = () => {
  return (
    <PageContent>
      <TopSection>
        <PageTitleWrapper>
          <PageTitleText>PRIVACY POLICY</PageTitleText>
        </PageTitleWrapper>
      </TopSection>
      <PageSection>
        <PageSectionTitle>What Information We Collect</PageSectionTitle>
        <Paragraph>
          We collect and use certain information to provide you with a
          personalized and secure experience. This information includes:
        </Paragraph>
        <Paragraph>
          <strong>User Identification and Authentication:</strong> When you sign
          up for an account with us, we support social authentication methods,
          including Sign in with Apple (available on iOS) and Login with Google
          (available on iOS and Android). When using social authentication, we
          collect your email address provided by the authentication provider
          when you sign in. For Sign in with Apple, you have the option to
          provide the App with an anonymized email address instead of the real
          email address. This option ensures your privacy while still allowing
          us to identify and authenticate you as a registered user of the App.
        </Paragraph>
        <Paragraph>
          <strong>In-App Subscriptions</strong> All payments are securely
          processed by the iOS App Store and Google Play Store; the App does not
          handle any payments. The App retrieves the current subscription status
          from these platforms and does not store any subscription details.
        </Paragraph>
        <Paragraph>
          <strong>User-Generated Content: </strong> The App enables you to
          upload and categorize images, such as objects and locations, along
          with supplementary text data. We ensure all data is stored securely
          and is strictly used to deliver the App's functionalities. We do not
          access, use, or share your information beyond what is essential for
          providing these services.
        </Paragraph>
        <Paragraph>
          <strong>App Usage Logs:</strong> The App generates a unique app ID
          upon its first use, which is stored locally on your device. This app
          ID is used for logging crashes, errors, and critical events on our
          server. This information helps us improve the App's functionality, fix
          bugs, and provide support to users who contact tech support for issue
          resolution. If a user is authenticated, an account identifier may be
          added to the logs for context. These logs are accessible only by the
          application developers and support staff and are not permanent. If you
          re-install the App or clear app local data, a new app ID will be
          generated.
        </Paragraph>
      </PageSection>
      <PageSection>
        <PageSectionTitle>How We Use Your Information</PageSectionTitle>
        <Paragraph>
          <strong>User Identification and Authentication:</strong> We use your
          email address (or the anonymized address provided via 'Sign in with
          Apple') along with the authentication token to identify and
          authenticate you as a registered user of the App. Your email address
          may also be used for essential communication related to your account.
        </Paragraph>
        <Paragraph>
          <strong>User-Generated Content:</strong> All the data you add through
          the App is stored securely and is associated with your account. We do
          not use your data for any purpose other than providing you with
          functionality within the App.
        </Paragraph>
        <Paragraph>
          <strong>App Usage Logs:</strong>The App usage logs help us identify
          and address technical issues, improve app performance, and provide
          timely support to users. User authentication information in the logs
          is used for context and issue resolution.
        </Paragraph>
      </PageSection>
      <PageSection>
        <PageSectionTitle>Information Storage and Security</PageSectionTitle>
        <Paragraph>
          We take the security of your personal information, user-generated
          content, and app usage logs seriously. They are stored securely and
          protected from unauthorized access.
        </Paragraph>
      </PageSection>
      <PageSection>
        <PageSectionTitle>Third-Party Services</PageSectionTitle>
        <Paragraph>
          For <strong>social authentication</strong> methods such as "Login with
          Google," the App utilizes the email address associated with your
          social account solely to confirm your identity as a registered user.
          We do not access, use, or store any additional user information
          provided by third-party services.
        </Paragraph>
      </PageSection>
      <PageSection>
        <PageSectionTitle>Updates to this Privacy Policy</PageSectionTitle>
        <Paragraph>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. Please review this Privacy Policy periodically.
        </Paragraph>
      </PageSection>
      <PageSection>
        <PageSectionTitle>Contact Us</PageSectionTitle>
        <Paragraph>
          If you have any questions about this Privacy Policy or our data
          practices, please contact us at{' '}
          <Link href="mailto:support@lastseen.app">support@lastseen.app</Link>
        </Paragraph>
      </PageSection>
    </PageContent>
  )
}

export default PrivacyPolicy
